<template>
  <div class="wrapper">
    <HeaderComponent :config="config">
      <template v-slot:logo>
        <slot name="logo">
          <img
            v-show="logoLoaded"
            :src="config.images.icon.webp"
            class="header__logo-image"
            alt="logo"
            @load="loadImage"
          />
        </slot>
      </template>
    </HeaderComponent>
    <main class="main">
      <div class="container">
        <div class="hero__inner">
          <div class="casino">
            <ul class="casino__cards casino__cards-1">
              <li
                v-for="{name, gameTitle, url, casinoBonus1} in config.projects"
                :key="name"
                class="casino-item"
                :class="name"
              >
                <a
                  class="casino-item__link"
                  :href="url"
                  target="_blank"
                >
                  <img
                    v-if="name"
                    :src="require(`./img/casino-${name}.webp`)"
                    class="casino-item__side-image"
                    alt="casino banner"
                  >
                  <div class="casino-item__content">
                    <div class="casino-item__top">
                      <img
                        v-if="name"
                        :src="require(`./img/casino-${name}.webp`)"
                        class="casino-item__image"
                        alt="casino banner"
                      >
                      <div class="casino-item__game-title">
                        <span>{{ gameBonus }} {{ gameTitle }} {{ gameBonusAfter }}</span>
                      </div>
                    </div>
                    <div class="casino-item__bonuses"  v-if="casinoBonus1">
                      <div class="casino-item__bonus" v-html="casinoBonus1 || config.casinoBonus1"></div>
                      <div class="casino-item__bonus" v-html="config.casinoBonus2"></div>
                    </div>
                    <div
                      class="casino-item__promocode"
                      @click.prevent="copyToClipboard(config.promocode)"
                    >
                      <span class="casino-item__your-promocode">{{ config.yourPromocode }}</span>
                      <span>{{ config.promocode }}</span>
                      <img
                        src="./img/icon-copy.svg"
                        class="casino-item__copy"
                        alt=""
                      >
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <FooterComponent :config="config"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import HeaderComponent from './blocks/header'
import FooterComponent from './blocks/footer'

export default defineComponent({
  name: 'template',
  data () {
    return {
      logoLoaded: false
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    gameBonus () {
      return this.config.gameBonus || ''
    },
    gameBonusAfter () {
      return this.config.gameBonusAfter || ''
    }
  },
  created () {
    const html = document.querySelector('html')
    const body = document.querySelector('body')
    document.addEventListener('scroll', (event) => {
      if (html.scrollTop > window.outerWidth * 3 / 100) {
        body.classList.add('sticky-top')

        return
      }

      body.classList.remove('sticky-top')
    })

    if (this.config.theme) {
      body.classList.add(`theme-${this.config.theme}`)
    }
  },
  methods: {
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$emit('copy')
      } catch ($e) {
        console.error('Cannot copy')
      }
    },
    loadImage () {
      console.log('loadImage')
      this.logoLoaded = true
    }
  }
})

</script>

<style lang="scss" src="./scss/style.scss"></style>
